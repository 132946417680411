<template>
  <b-container fluid>
    <div>
      <b-alert :show="messageText !== ''" fade dismissible :variant="messageVariant">{{messageText}}</b-alert>
      <b-modal header-bg-variant="primary" size="lg" ref="confirmationModal" id="confirmationModal" centered :title="$t(confirmationModalData.confirmationModalTitle)"
        @ok="confirmationModalData.confirmationModalOkHandler"
        @cancel="confirmationModalData.confirmationModalCancelHandler"
        :cancel-title="$t('Cancelar')">
        <moon-loader class="mb-2" :loading="loading" color="#000" size="30px"></moon-loader>
        <span v-show="!loading">{{confirmationModalData.confirmationModalText}}</span>
      </b-modal>
      <b-modal header-bg-variant="primary" size="lg" ref="successModal" id="successModal" centered :title="$t('Variante creada')"
        @ok="handleSuccess"
        :ok-title="$t('Aceptar')"
        :ok-only="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :hide-header-close="true">
        {{$t('Variante creada con éxito')}}
        <br>
        <strong>{{$t('Pulse Aceptar para volver a lista de productos')}}</strong>
      </b-modal>
      <b-row class="d-flex justify-content-between mb-2">
        <h5>{{title}}</h5>
        <div>
          <b-btn class="mr-3" variant="secondary" @click="$router.go(-1)"><i class="fa fa-arrow-left mr-2"></i>{{$t('Regresar')}}</b-btn>
          <button v-if="!isAdmin" :disabled="!product.id || !sku.id || !canDeleteSku" type="button" class="btn btn-primary mr-3"  @click="deleteSku">
            <i class="far fa-trash-alt"></i> {{$t('Eliminar Variante')}}
          </button>
          <router-link :to="{ path: '/incidents/list', query: { sku: sku.id }}">{{$t('Crear incidencia')}}</router-link>
        </div>
      </b-row>
      <moon-loader class="mb-2" :loading="loading" color="#000" size="30px"></moon-loader>
      <b-row>
        <b-col>
          <p v-show="product.skus && product.skus.length === 0" class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i> {{$t('Es obligatorio crear un SKU para que el producto sea vendible en el marketplace')}}</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-card v-show="!loading" :header="$t('Estados')" class="my-3" header-bg-variant="primary">
            <b-row>
              <b-col>
                <b-table v-show="!loading" id="productTable"
                  ref="variantTable"
                  show-empty
                  hover
                  :items="sku.marketplaces"
                  :fields="fieldsStatusByMarketplaces"
                  responsive=true
                  stacked="xl"
                  :empty-text="$t('No hay variantes para mostrar')"
                  :empty-filtered-text="$t('No hay variantes para mostrar')">
                  <template slot="status" slot-scope="item">
                    <b v-if="item.value === 'APPROVED'" class="text-success">
                      {{$t(estados.find(p => p.value === item.value).text)}}
                    </b>
                    <b v-if="item.value === 'REJECTED'" class="text-danger">
                      {{$t(estados.find(p => p.value === item.value).text)}}
                    </b>
                    <b v-if="item.value === 'PENDING_APPROVAL'" class="text-warning">
                      {{$t(estados.find(p => p.value === item.value).text)}}
                    </b>
                  </template>
                  <template slot="action" slot-scope="item">
                    <button type="button" @click="changeStatusModal(item.item, 'REJECTED')" v-show="isAdmin" 
                      :disabled="item.item.status === 'APPROVED' || item.item.status === 'REJECTED'" class="btn btn-primary float-right">
                      {{$t('Rechazar')}}
                    </button>
                    <button type="button" @click="changeStatusModal(item.item, 'APPROVED')" v-show="isAdmin" 
                      :disabled="item.item.status === 'APPROVED' || item.item.status === 'REJECTED'" class="btn btn-primary float-right mr-2">
                      {{$t('Activar')}}
                    </button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>

          <!-- <b-form-group
            id="estadoSelectGroup"
            :label-cols="2"
            breakpoint="md"
            :label="$t('Estado')"
            label-for="estadoSelectInput">
            <b-form-select id="estadoSelectInput"
              @change="changeStatusModal"
              required
              :disabled="sku.status !== 'PENDING_APPROVAL'"
              v-model="sku.status">
              <option v-for="item in estados" 
                :key="item.value"
                :value="item.value">{{$t(item.text)}}
              </option>
            </b-form-select>
          </b-form-group> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col class="p-0">
          <b-card v-show="!loading" :header="$t('Datos generales')" class="my-3" header-bg-variant="primary">
            <b-row>

              <b-col>
                <b-form-group id="upcInput"
                    :label-cols="2"
                    breakpoint="md"
                    :invalid-feedback="!isValidUPC(sku.upc, true, false)? $t('No es un valor válido') : ''"
                    :state="isValidUPC(sku.upc, true, false)"
                    :label="$t('UPC')">
                    <b-form-input
                      v-model="sku.upc"
                      :disabled="Boolean(sku.id)"
                    ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="skuInput"
                    :label-cols="2"
                    breakpoint="md"
                    :description="$t('Generado automáticamente por Vtex una vez aprobado')"
                    :label="$t('SKU')"
                    label-for="skuFormatterHelp">
                  <b-form-input type="number" id="skuFormatterHelp" v-model="sku.id" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="nombreInput"
                  :label-cols="2"
                  breakpoint="md"
                  :label="$t('Nombre')"
                  :description="$t('Máximo 100 caracteres')"
                  :invalid-feedback="!isValidText(sku.skuName, true) && !editionDisabled ? $t('El nombre no es válido') : ''"
                  :state="isValidText(sku.skuName, true)"
                  label-for="nombreFormatterHelp">
                  <b-form-input
                    id="nombreFormatterHelp"
                    v-model="sku.skuName"
                    :disabled="editionDisabled || isProductApprove"
                    maxlength="100">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group id="inventoryInput"
                    :label-cols="2"
                    breakpoint="md"
                    :invalid-feedback="!isValidInt32Integer(sku.inventory.totalQuantity, false, false)? $t('No es un inventario válido') : ''"
                    :state="isValidInt32Integer(sku.inventory.totalQuantity, false, false)"
                    :description="$t('Ingrese un valor de inventario válido')"
                    :label="$t('Inventario')">
                    <b-form-input
                      v-model="sku.inventory.totalQuantity"
                      :disabled="editionDisabled"
                    ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="refId"
                    :label-cols="2"
                    breakpoint="md"
                    :description="$t('Ingrese el código de referencia')"
                    :invalid-feedback="!isValidText(sku.refId, false, refIdMaxChars) && !editionDisabled ? $t('El código de referencia no es válido') : ''"
                    :state="isValidText(sku.refId, false, refIdMaxChars)"
                    :label="$t('Código de referencia')"
                    label-for="refId">
                  <b-form-input 
                      :disabled="editionDisabled || isProductApprove" 
                      type="text" 
                      id="refId" 
                      v-model="sku.refId"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="productServiceCode"
                    :label-cols="2"
                    breakpoint="md"
                    :description="$t('Ingrese el código de producto (definido en catálogo del SAT)')"
                    :invalid-feedback="!isValidText(sku.productServiceCode, false) && !editionDisabled ? $t('El código de producto no es válido') : ''"
                    :state="isValidText(sku.productServiceCode, false)"
                    :label="$t('Código de producto')"
                    label-for="productServiceCode">
                  <b-form-input 
                      :disabled="editionDisabled" 
                      type="text" 
                      id="productServiceCode" 
                      v-model="sku.productServiceCode"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="measurementUnitCode"
                    :label-cols="2"
                    breakpoint="md"
                    :description="$t('Ingrese la unidad de medida (definido en catálogo del SAT)')"
                    :invalid-feedback="!isValidText(sku.measurementUnitCode, false) && !editionDisabled ? $t('La unidad de medida no es válida') : ''"
                    :state="isValidText(sku.measurementUnitCode, false)"
                    :label="$t('Unidad de medida')"
                    label-for="measurementUnitCode">
                  <b-form-input 
                      :disabled="editionDisabled" 
                      type="text" 
                      id="measurementUnitCode" 
                      v-model="sku.measurementUnitCode"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="tariffFraction"
                    :label-cols="2"
                    breakpoint="md"
                    :description="$t('Ingrese la fracción arancelaria')"
                    :invalid-feedback="!isValidText(sku.tariffFraction, false) && !editionDisabled ? $t('La fracción arancelaria no es válida') : ''"
                    :state="isValidText(sku.tariffFraction, false)"
                    :label="$t('Fracción arancelaria')"
                    label-for="tariffFraction">
                  <b-form-input 
                      :disabled="editionDisabled" 
                      type="text" 
                      id="tariffFraction" 
                      v-model="sku.tariffFraction"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <price-and-dimencion :pricesDisabled="editionDisabled" :sizesDisabled="editionDisabled || isProductApprove" :priceData="sku.price" :dimension="sku.dimension"></price-and-dimencion>
      </b-row>
      <b-row>
        <image-loader :disabled="editionDisabled || isProductApprove" @imageJustLoaded="imageJustLoaded" @imageDeleted="_addImagesAndFinishCreation('', null, true)" :skuId="sku.id" :productImages="sku.images" :productVideos="sku.videos.videos" ref="imageLoader"></image-loader>
      </b-row>
      <b-row>
        <b-col class="p-0">
          <b-card v-show="!loading" :header="$t('Especificaciones')" class="my-3" header-bg-variant="primary">
            <product-attributes :disabled="editionDisabled || isProductApprove" :mergedInfo="specificationsComputedMap"></product-attributes>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <sku-history :history="history"></sku-history>
      </b-row>
      <b-row>
        <b-col class="p-0 mb-4">
          <hr>
          <b-button v-if="!this.isAdmin" variant="primary" class="float-right" @click="handleOk" :disabled="createButtonDisabled">Enviar</b-button>
          <b-button class="float-right mr-3" @click="handleCancel()">Cancelar</b-button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
import PriceAndDimencion from './PriceAndDimencion'
import ProductAttributes from './ProductAttributes'
import { ProductServiceMixim, PRODUCT_STATES_EDITION } from '@/mixims/ProductServiceMixim.js'
import ImageLoader from '@/components/common/ImageLoader'
import SkuHistory from './SkuHistory'

export default {
  name: 'sku-modal',
  components: {
    MoonLoader,
    PriceAndDimencion,
    ProductAttributes,
    ImageLoader,
    SkuHistory
  },
  props: {
    productId: Number,
    skuId: Number
  },
  mixins: [ValidationsMixim, ProductServiceMixim],
  data () {
    return {
      isAdmin: this.$store.getters.isAdmin,
      title: '',
      loading: false,
      specifications: [],
      mute: true,
      messageText: '',
      messageVariant: 'success',
      currentStatus: '',
      estados: PRODUCT_STATES_EDITION,
      confirmationModalData: {
        confirmationModalOkHandler: '',
        confirmationModalCancelHandler: '',
        confirmationModalText: '',
        confirmationModalTitle: ''
      },
      sku: {
        price: {},
        dimension: {},
        inventory: {},
        images: [],
        videos: {
          status: "PENDING", //"PENDING|SYNC",
          videos: []
        },
        skuSpecifications: []
      },
      product: {},
      history: [],
      fieldsStatusByMarketplaces: [
        { key: 'skuIdRef', label: this.$t('SKU'), 'class': 'text-left' },
        { key: 'name', label: this.$t('Sitio'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left' },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ]
    }
  },
  watch: {
  },
  computed: {
    isProductApprove () {
      return this.sku?.marketplaces?.some(site=>site?.status == 'APPROVED')
    },
    canDeleteSku () {
      return this.isProductApprove
    },
    okTitle: function () {
      return this.sku.id ? this.$t('Guardar') : this.$t('Crear Variante')
    },
    editionDisabled: function () {
      if (!this.sku.upc || !this.isValidUPC(this.sku.upc, true, false) || this.isAdmin) {
        return true
      }
      return false
    },
    createButtonDisabled: function () {
      if (!this.isValidText(this.sku.skuName, true)) {
        return true
      }

      if (!this.isValidText(this.sku.refId, false, this.refIdMaxChars)) {
        return true
      }

      if (!this.isValidText(this.sku.productServiceCode, false)) {
        return true
      }

      if (!this.isValidText(this.sku.measurementUnitCode, false)) {
        return true
      }

      if (!this.isValidText(this.sku.tariffFraction, false)) {
        return true
      }

      if (!this.isValidFloat(this.sku.dimension.height, true, true) || !this.isValidFloat(this.sku.dimension.length, true, true) || !this.isValidFloat(this.sku.dimension.weight, true, true) || !this.isValidFloat(this.sku.dimension.width, true, true)) {
        return true
      }

      if (!this.isValidFloat(this.sku.price.listPrice, true, true) || parseFloat(this.sku.price.listPrice) <= 0) {
        return true
      }

      if(this.sku.price.price === '0' || parseFloat(this.sku.price.price) <= 0) {
        return true
      }

      if (this.isValidFloat(this.sku.price.price, true) && !this.isValidFloat(this.sku.price.listPrice, true, true)) {
        return true
      }

      if (!this.isValidInt32Integer(this.sku.inventory.totalQuantity, false, false)) {
        return true
      }

      if (this.isValidFloat(this.sku.price.price, true) && this.isValidFloat(this.sku.price.listPrice, true, true)) {
        if (parseFloat(this.sku.price.price) > parseFloat(this.sku.price.listPrice)) {
          return true
        }
      }

      if (parseFloat(this.sku.price.price) < parseFloat(this.sku.price.listPrice)) {
        if (this.isEmptyData(this.sku.price.priceValidUntil)) return true

        let dateSelected = new Date(this.sku.price.priceValidUntil)
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        if (today > dateSelected) return true
      }

      if (this._pendingProps()) {
        return true
      }
      return !this.$refs.imageLoader.hasImages()
    },
    specificationsComputedMap: function () {
      if (!this.sku.skuSpecifications) {
        this.emptySpec()
      }
      let attributes = this.sku.skuSpecifications
      return this.specifications.map(spec => {
        let newAttr
        let foundAttr = attributes.find(attr => attr && attr.fieldId === spec.fieldId && attr.name === spec.name)
        if (!foundAttr) {
          newAttr = {
            ...spec,
            specificationFieldValues: [{}]
          }
          this.sku.skuSpecifications.push(newAttr)
        }
        return {
          spec,
          value: foundAttr !== undefined ? foundAttr : newAttr}
      })
    },
    refIdMaxChars () {
      if (process.env.VUE_APP_SKU_REF_ID_MAX_CHARS && parseInt(process.env.VUE_APP_SKU_REF_ID_MAX_CHARS)) {
        return parseInt(process.env.VUE_APP_SKU_REF_ID_MAX_CHARS)
      } else {
        return false
      }
    }
  },
  methods: {
    // changeStatusModal (status) {
    //   this.confirmationModalData.confirmationModalTitle = `${this.$t('Cambio de estado de')} ${this.sku.skuName}`
    //   this.confirmationModalData.confirmationModalText = `${this.$t('¿Cambiar el estado a')} ${status === 'REJECTED' ? this.$t('rechazado') : this.$t('activado')}?`
    //   this.confirmationModalData.confirmationModalOkHandler = () => {
    //     this.execChangeStatus()
    //   }
    //   this.confirmationModalData.confirmationModalCancelHandler = () => {
    //     this.sku.status = 'PENDING_APPROVAL'
    //     // this.hurryShow()
    //   }
    //   this.currentStatus = status
    //   this.$refs.confirmationModal.show()
    // },
    changeStatusModal (skuByMarketplace, newStatus) {
      let self = this
      this.confirmationModalData.confirmationModalTitle = `${this.$t('Cambio de estado')}`
      this.confirmationModalData.confirmationModalText = `${this.$t('¿Cambiar el estado a')} ${newStatus === 'REJECTED' ? this.$t('rechazado') : this.$t('activado')}?`
      this.confirmationModalData.confirmationModalOkHandler = () => {
        console.log('CAMBIAR ESTADO')
        self.execChangeStatus(skuByMarketplace, newStatus)
      }
      this.confirmationModalData.confirmationModalCancelHandler = () => {
        console.log('CANCELAR CAMBIAR ESTADO')
        // self.execChangeStatus(sku, skuByMarktplace, newStatus)
      }
      // this.currentStatus = status
      this.$refs.confirmationModal.show()
    },
    execChangeStatus (skuByMarketplace, newStatus) {
      // this.hurryShow()
      this.loading = true
      this.messageText = ''
      this.messageVariant = 'success'
      let errorVerv = ''
      let actionFunction = ''
      switch (newStatus) {
        case 'APPROVED':
          actionFunction = this.activateSku
          errorVerv = this.$t('aprobando')
          break
        case 'REJECTED':
          actionFunction = this.rejectSku
          errorVerv = this.$t('rechazando')
          break
      }
      actionFunction(this.sku.id, skuByMarketplace).then(
        (response) => {
          let res = response.body
          if (res.status && res.status.code) {
            if (res.status.code !== '200') {
              this.messageText = `${this.$t('Error')} ${errorVerv} ${this.$t('el producto')} ${this.sku.id}`
              this.messageVariant = 'danger'
              this.loading = false
            } else {
              this.messageText = ` ${this.$t('El estado fue modificado con éxito')}`
              this.messageVariant = 'success'
              this.fetchData()
            }
          }
          this.mute = false
        },
        (error) => {
          console.log(error)
          this.loading = false
          // this.sku.status = 'PENDING_APPROVAL'
          this.messageText = `${this.$t('Error')} ${errorVerv} ${this.$t('el producto')} ${this.sku.id}`
          this.messageVariant = 'danger'
          if (this.currentStatus === 'APPROVED') {
            if (this.sku.images.length === 0) {
              this.messageText += '<ul>'
              this.messageText += '  <li>' + this.$t('El producto no tiene imagenes cargadas.') + '</li>'
              this.messageText += '</ul>'
            }
          }
        }
      )
    },
    deleteSku () {
      this.confirmationModalData.confirmationModalTitle = `${this.$t('Eliminar SKU')}`
      this.confirmationModalData.confirmationModalText = `${this.$t('Se procederá a eliminar la variante')} ${this.sku.skuName}`
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.loading = true
        this.deleteProducts([this.sku.id], true).then(
          () => {
            this.loading = false
            this.$emit('needReload', {status: true, text: this.$t('La variante fue eliminada con éxito')})
            this.$refs.confirmationModal.hide()
            this.$router.push('/catalog/list?page=0&size=10')
          },
          (error) => {
            this.loading = false
            this.$emit('needReload', {status: false, text: error.body.message || this.$t('Error eliminando la variante')})
            this.$refs.confirmationModal.hide()
          }
        )
      }
      this.confirmationModalData.confirmationModalCancelHandler = () => {
        // this.hurryShow()
      }
      this.mute = true
      this.$refs.confirmationModal.show()
    },
    _addImagesAndFinishCreation (messageText, isCreation, silent) {
      let _this = this
      /* Upload the images */
      let temp = this.sku.dimension.height
      this.sku.dimension.height = 0
      this.sku.dimension.height = temp
      this.$refs.imageLoader.uploadImages(this.sku.id).then(
        (images) => {
          _this.sku.images = images
          if(!silent) {
            if (isCreation) {
              this.messageText = messageText
              this.messageVariant = 'success'
              this.loading = false
              this.$refs.successModal.show()
            } else {
              /* Update the SKU with the just upload images */
              this.updateSku(_this.sku.id, _this.sku).then(
                (skuJustCreated) => {
                  this.mute = false
                  this.messageText = messageText
                  this.messageVariant = 'success'
                  this.loading = false
                  this.$refs.successModal.show()
                },
                (error) => {
                  console.log(error)
                  if (error.status === 500) {
                    this.messageText = `${this.$t('Error al guardar la variante.')} ${this.$t('Revise los campos ingresados.')}`
                    this.messageVariant = 'danger'
                  } else {
                    this.messageText = this.$t('Error al guardar la variante.')
                    this.messageVariant = 'danger'
                  }
                  this.loading = false
                }
              )
            }
          }
        },
        (error) => {
          console.log(error)
          this.messageText = this.$t('Error ejecutando el servicio para subir las imágenes')
          this.messageVariant = 'danger'
          this.loading = false
        }
      )
    },
    _pendingProps () {
      return this.specificationsComputedMap.some(
        (prop) => {
          if (prop.value.isRequired) {
            return Object.keys(prop.value.specificationFieldValues).length === 0 || !prop.value.specificationFieldValues[0].value
          }
        }
      )
    },
    _createSku () {
      this.loading = true
      if (!this.$refs.imageLoader.hasImages()) {
        this.loading = false
        this.messageText = this.$t('No hay ninguna imagen seleccionada')
        this.messageVariant = 'danger'
        return
      }
      this.sku.videos.videos = this.$refs.imageLoader.getVideos()?.filter(x=> x !== null)
      console.log(this.product.id, this.sku)
      /* Create the SKU */
      this.createSku(this.product.id, this.sku).then(
        (res) => {
          this.sku.id = res.id
          this.sku.status = res.status
          /* We have the sku, so we can upload the images and update de sku */
          this._addImagesAndFinishCreation(this.$t('La variante fue creada con éxito'), true, false)
        },
        (error) => {
          console.log(error)
          this.messageText = this.$t('Error ejecutando el servicio de creación de variantes')
          this.messageVariant = 'danger'
          this.loading = false
        }
      )
    },
    _updateSku () {
      this.loading = true
      if (!this.$refs.imageLoader.hasImages()) {
        this.loading = false
        this.messageText = this.$t('No hay ninguna imagen seleccionada')
        this.messageVariant = 'danger'
        return
      }
      this.sku.videos.videos = this.$refs.imageLoader.getVideos()?.filter(x=> x !== null)
      console.log(this.product.id, this.sku)
      /* We have the sku, so we can upload the images and update de sku */
      this._addImagesAndFinishCreation(this.$t('La variante fue actualizada con éxito'), false, false)
    },
    handleOk (evt) {
      evt.preventDefault()
      this.messageText = ''
      if (!this.sku.id) {
        this.confirmationModalData.confirmationModalTitle = `${this.$t('Procesar alta de Variante:')} ${this.sku.skuName}`
      } else {
        this.confirmationModalData.confirmationModalTitle = `${this.$t('Procesar modificación de Variante:')} ${this.sku.skuName}`
      }
      this.confirmationModalData.confirmationModalText = this.$t('¿Desea continuar?')
      this.confirmationModalData.confirmationModalOkHandler = () => {
        if (!this.sku.id) {
          this._createSku()
        } else {
          this._updateSku()
        }
      }
      this.$refs.confirmationModal.show()
    },
    handleHide () {
      if (!this.mute) {
        this.$emit('needReload')
      }
    },
    imageJustLoaded () {
      this.messageText = ''
      let temp = this.sku.dimension.height
      this.sku.dimension.height = 0
      this.sku.dimension.height = temp
    },
    async fetchData () {
      if (this.skuId) {
        await this.getSku(this.skuId).then(
          (response) => {
            
            if (response && response.body) {
              if (!response.body.videos) {
                response.body.videos = {
                  status: "PENDING", //"PENDING|SYNC",
                  videos: []
                }
              }
            }

            this.sku = response.body
          }
        )
        await this.getSkuHistory(this.sku.id).then(
          (response) => {
            this.history = response != null ? response.body : []
          },
          (error) => {
            console.log(error)
            this.errorText = 'Error obteniendo el historial de la variante'
          }
        )
      } else {
        this.sku = {
          id: null,
          price: {},
          dimension: {},
          images: [],
          videos: {
            status: "PENDING", //"PENDING|SYNC",
            videos: []
          },
          inventory: {},
          commercialCondition: {},
          skuSpecifications: []
        }
      }

      await this.getProduct().then(
        (response) => {
          this.product = response.body
        }
      )
      this.specifications.splice(0, this.specifications.length)
      this.mute = true
      if (this.sku.id) {
        this.title = `${this.$t('Editar variante para el producto:')} ` + this.product.name
      } else {
        this.title = `${this.$t('Crear variante para el producto:')} ` + this.product.name
      }

      this.messageText = ''
      this.loading = true
      this.getSKUAttributes(this.product.category.id).then(
        (specifications) => {
          this.loading = false
          this.specifications = specifications.body
        },
        (error) => {
          console.log(error)
          this.loading = false
          this.messageText = this.$t('Error obteniendo las especificaciones de la variante')
          this.messageVariant = 'danger'
        }
      )
    },
    emptySpec () {
      this.sku.skuSpecifications = []
    },
    handleCancel () {
      this.$router.push('/catalog/list?page=0&size=10')
    },
    handleSuccess () {
      this.$router.push('/catalog/list?page=0&size=10')
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
</style>
