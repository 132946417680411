<template>
<b-container fluid class="mb-3">
  <b-alert :show="messageText !== ''" fade dismissible :variant="messageVariant">{{messageText}}</b-alert>
  <el-steps :active="active" finish-status="success">
    <el-step title="Datos Generales"></el-step>
    <el-step title="Especificaciones"></el-step>
    <el-step title="Finalizar"></el-step>
  </el-steps>
  <div id="steps">
    <product-form
      v-show="active === 0"
      :product="product"
      :state="state"
      @categorySelected="handleCategorySelected"
    />
    <b-card
      v-show="active === 1"
    >
      <h5 v-if="product.category">{{`${$t('Especificaciones')} para ${product.category.name}`}}</h5>
      <product-attributes :disabled="false" :mergedInfo="specificationsComputedMap"/>
    </b-card>
    <b-card
      v-show="active === 2"
    >
      <h5>Revisar y confirmar</h5>
      <review-and-confirm
        v-if="product.category"
        :product="product"
      />
    </b-card>
  </div>
  <b-modal header-bg-variant="primary" size="lg" ref="confirmationModal" id="confirmationModal" centered :title="$t(confirmationModalData.confirmationModalTitle)"
    @ok="confirmationModalData.confirmationModalOkHandler"
    :cancel-title="$t('Cancelar')">
    {{confirmationModalData.confirmationModalText}}
  </b-modal>
  <b-modal header-bg-variant="primary" size="lg" ref="createVariant" id="createVariant" centered :title="$t('¿Crear variante?')"
    @ok="goCreateVariant()"
    @cancel="handleCancel()"
    :ok-title="$t('Si')"
    :cancel-title="$t('No')">
    {{$t('El producto fue creado con exito')}}
    <br>
    <strong>{{$t('¿Desea crear una variante?')}}</strong>
  </b-modal>
  <b-button class="mr-2" @click="$router.go(-1)">Cancelar</b-button>
  <b-button class="mr-2" v-show="active > 0 && active < 3" @click="previousStep">Atrás</b-button>
  <b-button class="mr-2" variant="success" v-show="active < 2" :disabled="buttonDisabled" @click="nextStep">Siguiente</b-button>
  <b-button class="float-right" :disabled="saveClicked" variant="success" v-show="active === 2" @click="handleSave">Crear producto</b-button>
</b-container>
</template>

<script>
import ProductForm from '@/components/catalog/ProductForm'
import ProductAttributes from '@/components/catalog/ProductAttributes'
import ReviewAndConfirm from '@/components/catalog/ReviewAndConfirm.vue'

import {ValidationsMixim} from '@/mixims/ValidationsMixim.js'
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim'

export default {
  mixins: [ValidationsMixim, ProductServiceMixim],
  components: {
    ProductForm,
    ProductAttributes,
    ReviewAndConfirm
  },
  data () {
    return {
      active: 0,
      product: {
        id: null,
        skus: [],
        category: null,
        brand: [],
        productSpecifications: [],
        marketplaces: []
      },
      saveClicked: false,
      specifications: [],
      messageText: '',
      messageVariant: 'success',
      confirmationModalData: {
        confirmationModalOkHandler: '',
        confirmationModalCancelHandler: '',
        confirmationModalText: '',
        confirmationModalTitle: ''
      },
    }
  },
  computed: {
    specificationsComputedMap () {
      let attributes = this.product.productSpecifications
      return this.specifications.map(spec => {
        let newAttr
        let foundAttr = attributes.find(attr => attr && attr.name === spec.name)
        if (!foundAttr) {
          newAttr = {
            ...spec,
            specificationFieldValues: [{}]
          }
          this.product.productSpecifications.push(newAttr)
        }
        return {
          spec,
          value: foundAttr !== undefined ? foundAttr : newAttr}
      }).sort((a,b) => Number(b.spec.isRequired) - Number(a.spec.isRequired))
    },
    state () {
      return {
        name: this.isValidText(this.product.name, true),
        category: Boolean(this.product.category),
        brand: this.product.brand.length > 0,
        description: this.isValidTextArea(this.product.description, true),
        sites: this.product.marketplaces && this.product.marketplaces.length > 0
      }
    },
    buttonDisabled () {
      if (Object.values(this.state).includes(false)) {
        return true
      }
      if (this._pendingProps() && this.active === 1) {
        return true
      }
      return false
    }
  },
  methods: {
    nextStep () {
      if (this.active++ > 2) this.active = 0
    },
    previousStep () {
      if (this.active-- < 0) this.active = 0
    },
    handleCategorySelected (data) {
      this.specifications = data
    },
    _pendingProps () {
      return this.specificationsComputedMap.some(
        (prop) => {
          if (prop.value.isRequired) {
            return Object.keys(prop.value.specificationFieldValues[0]).length === 0 || !prop.value.specificationFieldValues[0].value
          }
        }
      )
    },
    handleSave (evt) {
      this.saveClicked = true
      evt.preventDefault()

      this.messageText = ''
      this.confirmationModalData.confirmationModalTitle = `${this.$t('Crear producto:')} ${this.product.name}`
      this.confirmationModalData.confirmationModalText = this.$t('¿Desea continuar?')
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.product.brand = this.product.brand[0]
        return this.createProduct(this.product).then(
          (res) => {
            this.product.id = res.id
            this.messageText = this.$t('El producto fue creado con exito')
            this.messageVariant = 'success'
            this.$refs.createVariant.show()
          },
          (error) => {
            this.messageText = error.body.message || this.$t('Error creando el producto')
            this.messageVariant = 'danger'
            this.saveClicked = false
          }
        )
      }
      this.$refs.confirmationModal.show()
    },
    goCreateVariant () {
      this.$router.push(`/catalog/details/${this.product.id}/sku`)
    },
    handleCancel () {
      this.$router.push('/catalog/list?page=0&size=10')
    }
  }
}
</script>
<style lang="scss" scoped>
#steps {
  margin-top: 20px;
  height: auto;
}
</style>
